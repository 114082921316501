import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { ArrowDownward as ArrowDownwardIcon, ArrowDropDown, ArrowUpward as ArrowUpwardIcon, CloseOutlined as CloseOutlinedIcon, DeleteOutline, EditOutlined, FilterList, SearchOutlined as SearchOutlinedIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { FirstPageOutlined, IosShare } from "@mui/icons-material";
import clsx from 'clsx';
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ASCENDING, DELETE, DESCENDING, EVENT_TABLE_COLUMNS, EVENTS_MODULE, GET, MENU, PATCH, SORTED_ASCENDING, SORTED_DESCENDING, TABLE_COLUMNS, TABLE_PAGES, USER_PROVIDER, USERS_MODULE } from "../../utility/constants";
import EnhancedEmptyTable from "../enhanced-empty";
import useStyles from "../enhanced-table/styles";
import Title from "../title";
import UserMenu from "../user-menu";
import { EnhancedTableHeadPropTypes, EnhancedTableProptypes, EnhancedTableRowPropTypes, EnhancedTableToolbarPropTypes } from "../utility/prop-types/enhanced-table";
import SkeletonRow from "./table-skeleton";
import { isArrayEqual } from "../../utility/helper";

export const createColumn = (id, label, isSortable, type, hidden = false, active = false, isSticky = false, module = '') => {

  return ({
    id,
    label,
    isSortable,
    type,   //Data type: "string", "boolean", "numeric", "date", "datetime", "time", "currency", "component"
    hidden,
    active,
    isSticky,
    module
  });
}

const SortHeader = (props) => {
  const classes = useStyles();
  const {active, columnId, getSortOrderDirection} = props;

  switch(getSortOrderDirection(columnId)){
    case ASCENDING:
      return (
        <ArrowUpwardIcon
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      ) 
    case DESCENDING:
      return (
        <ArrowDownwardIcon
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      )
    default: 
      return (
        <></>
      )
  }
}

const EnhancedTableHead = (props) => {
  const { values, functions, itemCount, totalSelectedItems, onSelectAll, isAllUserEntraIdUser, withCheckbox, withAction, label } = props;
  const { onRequestSort } = functions;
  const { order, orderBy, tableHeads, isSmallTable } = values;

  const classes = useStyles();

  const getSortOrder = () => {
    return (order === DESCENDING) ? SORTED_DESCENDING: SORTED_ASCENDING;
  }

  const getSortDirection = (id) => {
    return (orderBy === id) ? order : false;
  }

  const getSortOrderDirection = (id) => {
    return (orderBy === id) ? order : ASCENDING
  }

  return (
    <TableHead data-testid={`table-head`}>
      <TableRow>
        {
          withCheckbox &&
          <TableCell padding="checkbox">
            <Checkbox
              checked={itemCount > 0 && totalSelectedItems === itemCount}
              indeterminate={totalSelectedItems > 0 && totalSelectedItems < itemCount}
              inputProps={{ "aria-label": `select all ${label}` }}
              onChange={onSelectAll}
              disabled={isAllUserEntraIdUser}
            />
          </TableCell>
        }
        {
          tableHeads.map((column, index) => {
            return (
              <TableCell
                data-testid={`table-head-${index}`}
                key={'col-' + index}
                padding={'normal'}
                sortDirection={getSortDirection(column.id)}
                className={clsx(
                  isSmallTable ? classes.smallerTableHead : classes.headerStyle,
                  column.hidden && 'hidden'
                )}
              >
                {
                  column.isSortable ?
                    <TableSortLabel
                      data-testid={`table-head-label-${index}`}
                      direction={getSortOrderDirection(column.id)}
                      onClick={(event) => onRequestSort(column.id)(event)}
                      hideSortIcon={true}
                    >
                      <Box className={classes.columnHeader}>
                        <Box>
                          <SortHeader
                            columnId={column.id}
                            active={column.active}
                            getSortOrderDirection={getSortOrderDirection}
                          />
                        </Box>
                        <Box className={classes.columnItem}>
                          <Trans i18nKey={column.label}/>
                        </Box>
                      </Box>
                      {
                        orderBy === column.id &&
                        <span className={classes.visuallyHidden}>
                          {getSortOrder()}
                        </span>
                      }
                    </TableSortLabel>
                  :
                    <Box data-testid={`table-head-label-${index}`} className={classes.columnItem}>
                      <Trans i18nKey={column.label}/>
                    </Box>
                }

              </TableCell>
            )
          })
        }
        {
          withAction && <TableCell id={`table-cell-action-column`} className={clsx(classes.columnItem)} />
        }
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = EnhancedTableHeadPropTypes;

const EnhancedTableRow = (props) => {
  const { functions, values, label, onSelect, onUpdate, onDelete, withCheckbox, withAction, isRowSelected } = props;
  const { columns, data, isSmallTable, rowHoverId, selectedRow, module } = values;
  const { handleRowClick, onHover, handlePermissions } = functions;

  const classes = useStyles();
  const { t } = useTranslation();

  const keys = columns.map(column => column.id);
  const hasUpdatePermission = handlePermissions(module, PATCH);
  const hasDeletePermission = handlePermissions(module, DELETE);

  const handleClick = (event, rowId) => {
    const { id } = event.target.parentNode;
    if (id === MENU) {
      return;
    }
    handleRowClick(rowId)
  }

  return (
    <>
      {
        data.map((row, index) => {          
          const labelId = `enhanced-table-checkbox-${index}`;
          const isSelected = isRowSelected(row);
          const isEntraIdUser = row.provider === USER_PROVIDER.LDAP;

          return (
            <TableRow
              id={row.id}
              data-testid={`data-row-${index}`}
              hover
              tabIndex={-1}
              key={`data-row-${index}`}
              onClick={(e) => handleClick(e, row.id)}
              className={clsx(rowHoverId === row.id ? classes.dataRowHover : classes.dataRow, selectedRow === row.id && classes.selectedRow)}
              onMouseEnter={() => onHover(row.id)}
              onMouseLeave={() => onHover(null)}
              role="checkbox"
            >
              {
                withCheckbox &&
                <TableCell
                  id={`table-cell-checkbox-${index}`}
                  data-testid={`table-cell-checkbox-${index}`}
                  key={`table-cell-checkbox-${index}`}
                  padding="checkbox"
                >
                  {
                    module === USERS_MODULE && isEntraIdUser ?
                      <Tooltip
                        title={t('table-component.entraIdUserCheckbox')}
                        classes={{
                          tooltip: classes.customTooltip
                        }}
                        placement="top"
                      >
                        <span>
                          <Checkbox
                            id={`entra-id-user-checkbox-${index}`}
                            checked={false}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                            disabled={true}
                          />
                        </span>
                      </Tooltip>
                      :
                      <Checkbox
                        id={`checkbox-${index}`}
                        checked={isSelected}
                        color="primary"
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(event) => {
                          onSelect(event, row)
                        }}
                      />
                  }
                </TableCell>
              }
              {
                keys.map((_, ctr) => {
                  // Show only the data based on the column provided for the table
                  const permittedData = Object.fromEntries(Object.entries(row).filter(([key]) => keys.includes(key)))

                  // Get the cell content from the permitted column
                  const cellContent = permittedData[keys[ctr]];

                  const elementIdColumn = keys[ctr].charAt(0).toUpperCase() + keys[ctr].slice(1);
                  const currentColumn = columns.find(column => column.id === keys[ctr]);
                  
                  return (
                    <TableCell
                      key={`data-cell-${index}-${ctr}`}
                      data-testid={`data-cell-${index}-${ctr}`}
                      className={
                        clsx(
                          classes.baseCell,
                          {
                            [classes.stickyTableCell]   : currentColumn.isSticky,
                            [classes.smallerTableCell]  : !currentColumn.isSticky && isSmallTable,
                            [classes.timeColumn]        : elementIdColumn === EVENT_TABLE_COLUMNS.TIME,
                            [classes.selectedStickyCell]: selectedRow === row.id,
                            [classes.credentialsCell]   : elementIdColumn === EVENT_TABLE_COLUMNS.CREDENTIALS,
                            'hidden'                    : currentColumn.hidden
                          },
                        )} 
                    >
                      {
                        <span id={`${ctr}${elementIdColumn}`}>
                          {
                            cellContent ?
                              cellContent
                            :
                              ''
                          }
                        </span>
                      }
                    </TableCell>
                  )
                })
              }
              {
                withAction &&
                  <TableCell
                    id={`table-cell-action-column-${row.id}`} 
                    key={`table-cell-action-column-${row.id}`} 
                    padding="none"
                  >
                    {
                      <Box className={clsx(classes.actionButtonCell, {
                        [classes.inactiveRow] : row.id !== rowHoverId
                      })}>
                        <Tooltip title={`${t('table-component.update')} ${t('user')}`}>
                          <IconButton 
                            id={`icon-button-edit-${index}`} 
                            className={hasUpdatePermission ? classes.actionButton : 'hidden'} 
                            aria-label={`Update ` + label} 
                            onClick={(event) => onUpdate(event, row.id)}
                            >
                            <EditOutlined className={classes.icon}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={`${t('table-component.remove')} ${t('user')}`}>
                          <IconButton 
                            id={`icon-button-delete-${index}`} 
                            className={hasDeletePermission && !isEntraIdUser ? classes.actionButton : 'hidden'} 
                            aria-label={`Remove ` + label} 
                            onClick={(event) => onDelete(event, row)}
                          >
                            <DeleteOutline className={classes.icon}/>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    }
                  </TableCell>
              }
            </TableRow>
          );
        })
      }
    </>
  );
}
EnhancedTableRow.propTypes = EnhancedTableRowPropTypes;

const EnhancedTableToolbar = (props) => {
  const { values, functions } = props;
  const { page, rowsPerPage, isLoading, keyword, data, totalItems, isTotalItemsLoading, module, title, isTableListPage } = values;
  const { onPageChange, handleSearch, handleClearSearch, handleFilter, onFirstPage } = functions;

  const classes = useStyles();
  const { t }   = useTranslation();

  const showFilterComponent = module === EVENTS_MODULE;

  const countElement = (count, to) => {
    return count !== -1 ? count : `more than ${to}`
  }

  const countTableItems = (from, to) => {
    from = to === 0 ? 0 : from;
    return `${from}–${to} ${t('of')}`;
  }

  const getDisabledProp = () => {
    return {
      disabled: isLoading || isTotalItemsLoading
    }
  }

  const getCustomProp = () => {
    return (isLoading || isTotalItemsLoading) && getDisabledProp()
  }

  return (
    <Toolbar data-testid={`table-toolbar`} className={classes.toolbar}>
      <Grid container className={isTableListPage ? classes.toolBarItemsContainer: classes.endToolBarItemsContainer}>
        {
          isTableListPage ?
            <>
              <Grid item xs={3} lg={4}>
                <TextField
                  data-testid={`table-search-bar`}
                  fullWidth
                  className={classes.searchField}
                  id={`SearchBar1`}
                  onChange={event => handleSearch(event)}
                  placeholder={t('search')}
                  value={keyword}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon color="secondary" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id={`ClearSearchBar`}
                          edge="end"
                          onClick={handleClearSearch}
                          size="small"
                          className={
                            clsx({
                              [classes.visuallyHidden]: (keyword === null || keyword === "")
                            })
                          }
                        >
                          <CloseOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              {
                showFilterComponent ?
                  <Grid item xs={3} lg={3} className={clsx(classes.filterContainer)}>
                    <Tooltip title={t('table-component.filter-list')}>
                      <Button data-testid={`table-filter-button`} className={classes.filterButton} onClick={handleFilter}>
                        <FilterList />
                        {t('table-component.show-filters')}
                      </Button>
                    </Tooltip>
                  </Grid>
                :
                  <></>
              }
            </>
          :
            <Typography className={classes.title}>
              {title}
            </Typography>
        }
        <Grid item xs={showFilterComponent ? 6: 9} lg={showFilterComponent ? 5 : 8} style={{alignSelf:'center'}}>
          <Box container className={classes.paginationContainer}>
            {
              isTotalItemsLoading ?
                <Skeleton variant="rect" height={24} width={96}/>
              : 
                <></>
            }
            {
              data.length || isLoading ?
                <>
                  <IconButton
                    data-testid="first-page-button"
                    id="firstPageButton"
                    size="medium"
                    onClick={onFirstPage}
                    className={ clsx({
                      [classes.visuallyHidden]: (page < 2 || isTotalItemsLoading)
                    })}
                  >
                    <FirstPageOutlined />
                  </IconButton>
                  <TablePagination
                    data-testid={`table-pagination`}
                    component="div"
                    className={isTotalItemsLoading ? classes.paginationHide: ''}
                    labelRowsPerPage={t('rowPerPage')}
                    labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${countTableItems(from, to)} ${countElement(count, to)}`; }}
                    count={totalItems}
                    onPageChange={onPageChange}
                    page={page - 1}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    SelectProps={getDisabledProp()}
                    backIconButtonProps={getCustomProp()}
                    nextIconButtonProps={getCustomProp()}
                  />
                </>
              :
                <></>     
            }
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

const StickyEnhancedTableContent = (props) => {
  const { module, label, tableWidth, stickyTableHeads, tableHeads, data, totalSelectedItems, stickyColumns, columns, order, orderBy, rowHoverId, selectedRow, handleRowClick, onHover, onUpdate, onDelete, onRequestSort, onSelectAll, onSelect, handlePermissions, isTableListPage, isSmallTable, isRowSelected, isAllUserEntraIdUser, withCheckbox, withAction } = props;
  const classes = useStyles();

  const stickyRef    = useRef();
  const nonStickyRef = useRef();

  const onScroll = (scroll) => {
    stickyRef.current.scrollTop    = scroll.target.scrollTop;
    nonStickyRef.current.scrollTop = scroll.target.scrollTop;
    stickyRef.current.scrollLeft   = 0;
  };

  return (
    <>
      <div
        onScroll={onScroll}
        ref={stickyRef}
        className={clsx(isTableListPage? classes.stickyColumns: classes.fixedStickyColumns, tableWidth <= 1427 && classes.divider)}
      >
        <Table data-testid={`main-table`} className={classes.stickyMainTable} aria-labelledby="tableTitle" size={'medium'}>
          <EnhancedTableHead
            values={{
              order: order,
              orderBy: orderBy,
              tableHeads: stickyTableHeads,
              isSmallTable: isSmallTable
            }}
            functions={{
              onRequestSort: onRequestSort
            }}
            label={label}
            itemCount={data.length}
            totalSelectedItems={totalSelectedItems}
            onSelectAll={onSelectAll}
            isAllUserEntraIdUser={isAllUserEntraIdUser}
            withCheckbox={withCheckbox}
            withAction={false}
          />
          <TableBody data-testid={`table-body`}>
            <EnhancedTableRow 
              values={{
                columns: stickyColumns,
                data: data,
                isSmallTable: isSmallTable,
                rowHoverId: rowHoverId,
                selectedRow: selectedRow,
                module: module
              }}
              functions={{
                handleRowClick: handleRowClick, 
                onHover: onHover, 
                handlePermissions: handlePermissions,
              }}
              label={label}
              onSelect={onSelect}
              onUpdate={onUpdate}
              onDelete={onDelete}
              withCheckbox={withCheckbox}
              isRowSelected={isRowSelected}
            />
          </TableBody>
        </Table>
      </div>
      <div
        onScroll={onScroll}
        ref={nonStickyRef}
        className={isTableListPage ? classes.nonStickyColumns : classes.fixedNonStickyColumns}
      >
        <Table data-testid={`main-table`} className={classes.mainTable} aria-labelledby="tableTitle" size={'medium'}>
          <EnhancedTableHead
            values={{
              order,
              orderBy,
              tableHeads,
              isSmallTable
            }}
            functions={{
              onRequestSort
            }}
            label={label}
            withCheckbox={false}
            withAction={withAction}
          />
          <TableBody data-testid={`table-body`}>
            <EnhancedTableRow
              values={{
                columns,
                data,
                isSmallTable,
                rowHoverId,
                selectedRow,
                module
              }}
              functions={{
                handleRowClick,
                onHover,
                handlePermissions
              }}
              label={label}
              onUpdate={onUpdate}
              onDelete={onDelete}
              withCheckbox={false}
              withAction={withAction}
              isRowSelected={isRowSelected}
            />
          </TableBody>
        </Table>
      </div>
    </>
  )
}


EnhancedTableToolbar.propTypes = EnhancedTableToolbarPropTypes;

const EnhancedTableContent = (props) => {
  const { module, label, tableHeads, data, columns, order, orderBy, rowHoverId, selectedRow, handleRowClick, onHover, onRequestSort, handlePermissions, isSmallTable, isRowSelected, withCheckbox, withAction } = props;

  const classes = useStyles();

  return (
    <div className={classes.nonStickyColumns}>
      <Table data-testid={`main-table`} className={classes.mainTable} aria-labelledby="tableTitle" size={'medium'}>
        <EnhancedTableHead
          values={{
            order, 
            orderBy, 
            tableHeads, 
            isSmallTable
          }}
          functions={{
            onRequestSort: onRequestSort
          }} 
          label={label}
          withCheckbox={withCheckbox}
          withAction={withAction}
        />
        <TableBody data-testid={`table-body`}>
          <EnhancedTableRow 
            values={{
              columns, 
              data, 
              isSmallTable, 
              rowHoverId, 
              selectedRow, 
              module
            }}
            functions={{
              handleRowClick, 
              onHover, 
              handlePermissions
            }}
            label={label}
            withCheckbox={withCheckbox}
            withAction={withAction}
            isRowSelected={isRowSelected}
          />
        </TableBody>
    </Table>
    </div >
  );
}
// EnhancedTableContent.propTypes = EnhancedTableRowPropTypes;

const EnhancedTable = (props) => {
  const { columns, page, rowsPerPage, data, isLoading, onChangePage, onClearSearch, onSearch, label,
    onSort, keyword, totalItems, order, orderBy, onFilter, isTotalItemsLoading, module, handleRowClick, selectedRow, title, handlePermissions,
    listType, onCreate, onExport, onImport, withCheckbox, withAction, onUpdate, onDelete, onMultipleDelete } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const tableRef = useRef(null);

  const [tableHeads, setTableHeads] = useState(columns);
  const [tableWidth, setTableWidth] = useState(0);
  const [rowHoverId, setRowHoverId] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);

  const isTableListPage = TABLE_PAGES.hasOwnProperty(module);
  const isRowSelected = (id) => selected.indexOf(id) !== -1;
  const isAllUserEntraIdUser = data.every(user => user.provider === USER_PROVIDER.LDAP);

  const updateTableWidth = () => {
    if (tableRef.current) {
      const newWidth = tableRef.current.offsetWidth;
      setTableWidth(newWidth);
    }
  };

  useEffect(() => {
    let delayDebounce;
    let currentProp = tableRef.current;
    updateTableWidth();

    const resizeObserver = new ResizeObserver(() => {
      delayDebounce = setTimeout(() => {
        updateTableWidth();
      }, 250);
    });

    if (currentProp) {
      resizeObserver.observe(currentProp);
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
      if (currentProp) {
        resizeObserver.unobserve(currentProp);
      }
    };
  }, []);

  useEffect(() => {
    setSelected([]);
  }, [totalItems])

  const handleSearch = (event) => {
    event.stopPropagation();
    onSearch(event.target.value);
    setSelected([]);
  }

  const handleClearSearch = () => {
    onClearSearch();
    setSelected([]);
  }

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage);
    setSelected([]);
  };

  const handleFirstPage = () => {
    onChangePage(0);
  }

  const handleRequestSort = (event, newOrderBy) => {
    event.stopPropagation();
    const newOrder = order === ASCENDING ? DESCENDING : ASCENDING;
    onSort(newOrderBy, newOrder);
    setSelected([]);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
    const index = columns.findIndex(obj => obj.id === property)
    columns.map((column) => {
      column.active = false
      return null
    })
    columns[index].active = true

    setTableHeads(columns);
    setSelected([]);
  };

  const onHover = (rowId) => {
    setRowHoverId(rowId);
  }

  const handleUpdate = (event, item) => {
    event.stopPropagation();
    onUpdate(item);
  }

  const handleDelete = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete(item);
  }

  const handleMultipleDelete = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    onMultipleDelete(selected);
  }

  const getPermittedColumns = (columns) => {
    return columns.filter(head => handlePermissions(head.module, GET) || head.module === '');
  }

  const getRowData = (rows, columns) => {
    // return empty array if no data found
    if (!columns.length) {
      return columns;
    }

    return rows.map(obj => {
      return Object.fromEntries(
        Object.entries(obj).filter(([key]) => columns.includes(key))
      );
    });
  }

  const handleCreateButton = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  }

  const handleCloseCreateButton = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleSelectAll = (event) => {
    event.stopPropagation();
    const newSelecteds = data.filter((item) => item.provider !== USER_PROVIDER.LDAP);    
    const newSelectedIds = newSelecteds.map(selected => selected.id);
    const selectedIds = selected.map(selected => selected.id);

    setSelected((isArrayEqual(newSelectedIds, selectedIds) || !event.target.checked) ? [] : newSelecteds);
  };

  const handleSelect = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

/**
 * Checks whether all objects in an array contain only the 'id' property.
 * If true, the data does not include any sticky column data.
 */
  const hasOnlyIdProperty = (data, stickyHeaders) => {
    const stickyColumnIds = stickyHeaders.map(column => column.id);
    const stickyData = getRowData(data, stickyColumnIds);

    return stickyData.every(obj => Object.keys(obj).length === 1 && obj.hasOwnProperty(TABLE_COLUMNS.ID));
  }

  const stickyHeaders = tableHeads.filter(column => column.isSticky || column.id === TABLE_COLUMNS.ID);
  const nonStickyHeaders = tableHeads.filter(column => !column.isSticky);
  const hasNoStickyData = hasOnlyIdProperty(data, stickyHeaders);
  const nonStickyTableHeads = getPermittedColumns(nonStickyHeaders);
  const stickyTableHeads = getPermittedColumns(stickyHeaders);

  const tableToolbarProps = {
    values: {
      rowsPerPage, 
      page, 
      isLoading,
      isTotalItemsLoading,
      keyword,
      data,
      totalItems,
      module,
      title,
      isTableListPage
    },
    functions: {
      onPageChange: handleChangePage, 
      handleSearch: handleSearch, 
      handleClearSearch: handleClearSearch, 
      handleFilter: onFilter,
      onFirstPage: handleFirstPage
    }
  }

  const emptyTableHeadProps = {
    values: {
      order, 
      orderBy, 
      rowCount: data.length, 
      tableHeads: getPermittedColumns(tableHeads),
      isSmallTable: tableWidth <= 1098 ? true : false
    },
    functions: {
      onRequestSort: createSortHandler
    },
    withCheckbox: withCheckbox
  }

  return (
    <>
      {
        isTableListPage &&
        <Box className={clsx(classes.header, {[classes.headerSpaceBetween] : (module === USERS_MODULE && selected.length === 0)}) }>
          <Title title={title} listType={listType} />
          {
            selected.length > 0 && 
            <Box className={classes.selectedItemsContainer}>
              <Typography variant="body1">
                {`${selected.length} ${t('selected')}`} 
              </Typography>
              <Tooltip title={t('table-component.removeSelectedUsers')}>
                <IconButton 
                  id={`icon-button-delete-selected-items`} 
                  onClick={(event) => handleMultipleDelete(event, selected)}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Box>
          }
          {
            module === USERS_MODULE && selected.length === 0 &&
            <Box className={classes.actionsContainer}>
              <Button data-testid={`export-user-lists-button`} variant="text" color="primary" className={classes.button} onClick={onExport}>
                <IosShare />
                {t('table-component.exportUsersList')}
              </Button>
              <Box className={classes.iconBox}>
                <Button data-testid={`add-new-user-button`} variant="contained" color="primary" className={classes.button} onClick={handleCreateButton}>
                  {t('table-component.addNewUser')}
                  <ArrowDropDown />
                </Button>
                <UserMenu
                  handleCreate={onCreate}
                  handleImport={onImport}
                  handleCloseMenu={handleCloseCreateButton}
                  anchorEl={anchorEl}
                  open={open}
                />
              </Box>
            </Box>
          }
        </Box>
      }
      <Box className={isTableListPage ? classes.tableBox : classes.fixedTableBox} ref={tableRef}>
        <EnhancedTableToolbar {...tableToolbarProps} />
        <Paper className={clsx(data.length > 0 && classes.paper, classes.cutBar)} elevation={2}>
          <TableContainer className={classes.toolView}>
            {
              isLoading ?
                <Table data-testid={`main-table`} className={classes.stickyMainTable} aria-labelledby="tableTitle" size={'medium'}>
                  <EnhancedTableHead {...emptyTableHeadProps}/>
                  <SkeletonRow columns={getPermittedColumns(columns)} rowsPerPage={12} withCheckbox={withCheckbox}/>
                </Table>
              :
                data.length > 0 ?
                  <>
                    {
                      hasNoStickyData ?
                        <EnhancedTableContent
                          module={module}
                          label={label}
                          tableHeads={nonStickyTableHeads}
                          data={data}
                          columns={nonStickyTableHeads}
                          order={order}
                          orderBy={orderBy}
                          rowHoverId={rowHoverId}
                          selectedRow={selectedRow}
                          handleRowClick={handleRowClick}
                          onHover={onHover}
                          onRequestSort={createSortHandler}
                          handlePermissions={handlePermissions}
                          isSmallTable={tableWidth <= 1098 ? true : false}
                          isRowSelected={isRowSelected}
                          withCheckbox={withCheckbox}
                          withAction={withAction}
                        />
                        :
                        <StickyEnhancedTableContent
                          module={module}
                          label={label}
                          tableWidth={tableWidth}
                          stickyTableHeads={stickyTableHeads}
                          tableHeads={nonStickyTableHeads}
                          data={data}
                          totalItems={totalItems}
                          totalSelectedItems={selected.length}
                          stickyColumns={stickyHeaders}
                          columns={nonStickyTableHeads}
                          order={order}
                          orderBy={orderBy}
                          rowHoverId={rowHoverId}
                          selectedRow={selectedRow}
                          handleRowClick={handleRowClick}
                          onHover={onHover}
                          onUpdate={handleUpdate}
                          onDelete={handleDelete}
                          onRequestSort={createSortHandler}
                          onSelectAll={handleSelectAll}
                          onSelect={handleSelect}
                          handlePermissions={handlePermissions}
                          isTableListPage={isTableListPage}
                          isSmallTable={tableWidth <= 1098 ? true : false}
                          isRowSelected={isRowSelected}
                          isAllUserEntraIdUser={isAllUserEntraIdUser}
                          withCheckbox={withCheckbox}
                          withAction={withAction}
                        />
                    }
                  </>
                :
                  <EnhancedEmptyTable label={label}/>
            }
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
EnhancedTable.propTypes = EnhancedTableProptypes;

export default EnhancedTable;